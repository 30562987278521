import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [currentFlag, setCurrentFlag] = useState(null);
  const [userGuess, setUserGuess] = useState('');
  const [loading, setLoading] = useState(true);
  const [allCountries, setAllCountries] = useState([]);
  const [currentSuggestions, setCurrentSuggestions] = useState([]);
  const [suggestionIndex, setSuggestionIndex] = useState(0);
  const [showRegionDropdown, setShowRegionDropdown] = useState(false);
  const [showModeDropdown, setShowModeDropdown] = useState(false);
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('all');
  const [mode, setMode] = useState('normal'); // "normal" or "speedrun"
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(60); // Countdown for speedrun
  const [gameOver, setGameOver] = useState(false);
  const [hasError, setHasError] = useState(false); // State for input error animation
  const [timerStarted, setTimerStarted] = useState(false); // Track if speedrun timer has started
  const [showAnswer, setShowAnswer] = useState(false);


  const API_BASE_URL = 'https://flagquiz-api.onrender.com/';

  const fetchAllCountries = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/countries`);
      setAllCountries(response.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchNewFlag = async () => {
    try {
      setLoading(true);
      const endpoint = selectedRegion === 'all' ? '/flag' : `/flag/${selectedRegion}`;
      const response = await axios.get(`${API_BASE_URL}${endpoint}`);
      const flagData = { ...response.data, flag_url: `/${response.data.flag_path}` };
      setCurrentFlag(flagData);
    } catch (error) {
      console.error('Error fetching flag:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userGuess.toLowerCase() === currentFlag?.name.toLowerCase()) {
      setUserGuess('');
      fetchNewFlag();
      if (mode === 'speedrun') setScore(score + 1);
      setHasError(false); // Reset error state on correct answer
    } else {
      setHasError(true); // Trigger error animation on wrong answer
      setTimeout(() => setHasError(false), 1000); // Clear error state after 1 second
    }
    setUserGuess('');
    setCurrentSuggestions([]);
    setSuggestionIndex(0);
  };

  const handleKeyDown = (e) => {
    if (!timerStarted && mode === 'speedrun') {
      setTimerStarted(true); // Start timer on first keypress
    }

    if (e.key === 'Tab') {
      e.preventDefault();
      if (userGuess) {
        if (currentSuggestions.length === 0) {
          const matches = allCountries
            .filter(country => country.toLowerCase().startsWith(userGuess.toLowerCase()))
            .sort();
          if (matches.length > 0) {
            setCurrentSuggestions(matches);
            setUserGuess(matches[0]);
          }
        } else {
          const nextIndex = (suggestionIndex + 1) % currentSuggestions.length;
          setSuggestionIndex(nextIndex);
          setUserGuess(currentSuggestions[nextIndex]);
        }
      }
    } else if (e.key !== 'Tab') {
      setCurrentSuggestions([]);
      setSuggestionIndex(0);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const regionsResponse = await axios.get(`${API_BASE_URL}/regions`);
        setRegions(regionsResponse.data);
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    fetchNewFlag();
    fetchAllCountries();
  }, [selectedRegion]);

  useEffect(() => {
    if (mode === 'speedrun' && timerStarted && timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (mode === 'speedrun' && timeLeft === 0) {
      setGameOver(true);
    }
  }, [timeLeft, timerStarted, mode]);

  const handleRestart = () => {
    setGameOver(false);
    setScore(0);
    setTimeLeft(60);
    setTimerStarted(false);
    fetchNewFlag();
  };

  const handleSkip = () => {
    setShowAnswer(true); // Affiche la réponse
    setTimeout(() => {
      setShowAnswer(false); // Cache la réponse après 2 secondes
      fetchNewFlag(); // Passe au drapeau suivant
    }, 2000); // Délai pour afficher la réponse
  };
  
  return (
    <div className="app-container">
      <nav className="navbar">
        <div className="nav-brand">&#127758;  Flag Quiz</div>
        <div className="nav-modes">
          {/* Dropdown for regions */}
          <div className="dropdown">
            <button 
              className="mode-button"
              onClick={() => setShowRegionDropdown(!showRegionDropdown)}
            >
              {selectedRegion === 'all' ? 'All Flags' : selectedRegion}
              <span className="dropdown-arrow">▼</span>
            </button>
            {showRegionDropdown && (
              <div className="dropdown-menu">
                <div
                  className="dropdown-item"
                  onClick={() => {
                    setSelectedRegion('all');
                    setShowRegionDropdown(false);
                  }}
                >
                  All Flags
                </div>
                {regions.map(region => (
                  <div
                    key={region}
                    className="dropdown-item"
                    onClick={() => {
                      setSelectedRegion(region);
                      setShowRegionDropdown(false);
                    }}
                  >
                    {region}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Dropdown for mode */}
          <div className="dropdown">
            <button 
              className="mode-button"
              onClick={() => setShowModeDropdown(!showModeDropdown)}
            >
              {mode === 'normal' ? 'Normal Mode' : 'Speedrun Mode'}
              <span className="dropdown-arrow">▼</span>
            </button>
            {showModeDropdown && (
              <div className="dropdown-menu">
                <div
                  className="dropdown-item"
                  onClick={() => {
                    setMode('normal');
                    setShowModeDropdown(false);
                  }}
                >
                  Normal Mode
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => {
                    setMode('speedrun');
                    setShowModeDropdown(false);
                    handleRestart();
                  }}
                >
                  Speedrun Mode
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>

      <div className="flag-quiz">
        {gameOver ? (
          <div className="game-over">
          <p className="game-over-message">⏰ Time's up! Your score: <span className="score-highlight">{score}</span></p>
          <button className="play-again-button" onClick={handleRestart}>
            🔄 Play Again
          </button>
        </div>        
        ) : loading ? (
          <p>Loading...</p>
        ) : currentFlag ? (
          <>
            {/* Afficher le temps et le score uniquement en mode speedrun */}
            {mode === 'speedrun' && (
              <div className="game-info">
                <div className="time-left">⏱️ Time Left: {timeLeft}s</div>
                <div className="score">🏆 Score: {score}</div>
              </div>
            )}

            <div className="flag-container">
              <img src={currentFlag.flag_url} alt="Guess this flag" />
            </div>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={userGuess}
                onChange={(e) => setUserGuess(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Enter country name"
                autoFocus
                className={hasError ? 'error' : ''}
              />
            </form>
          </>
        ) : (
          <p>Error loading flag</p>
        )}

      
      {!gameOver && (
        <div className="skip-container">
          <button className="skip-button" onClick={handleSkip}>
            ⏭️ Skip
          </button>
          {showAnswer && currentFlag && (
            <p className="answer-reveal">The correct answer is: <span>{currentFlag.name}</span></p>
          )}
        </div>
      )}

      </div>
    </div>
  );
}

export default App;
